// @flow strict
import React from 'react';
import { withPrefix, Link } from 'gatsby';
import styles from './Author.module.scss';
import { getContactHref } from '../../../utils';

type Props = {
  author: {
    name: string,
    bio: string,
    photo: string
  },
  isIndex: ?boolean
};

const Author = ({ author, isIndex }: Props) => (
  <div className={styles['author']}>
    <Link to={getContactHref('twitter', author.contacts.twitter)}>
      <img
        src={withPrefix(author.photo)}
        className={styles['author__photo']}
        width="85"
        height="85"
        alt={author.name}
      />
    </Link>

    { isIndex === true ? (
      <h1 className={styles['author__title']}>
        <Link className={styles['author__title-link']} to="/">{author.name}</Link>
      </h1>
    ) : (
      <h2 className={styles['author__title']}>
        <Link className={styles['author__title-link']} to="/">{author.name}</Link>
      </h2>
    )}
    <p className={styles['author__subtitle']}>
      {author.bio}
      <span className={styles['author__bio-twitter']}>
          <a
            href={getContactHref('twitter', author.contacts.twitter)}
            rel="noopener noreferrer"
            target="_blank"
          >
          <strong>Follow me</strong> on Twitter
        </a>
      </span>
    </p>
  </div>
);

export default Author;
